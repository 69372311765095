<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">个人中心</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form  ref="form" :model="form" :rules="rules" label-width="80px" class="w-50">
				<el-form-item label="用户名" prop="user_name">
					<el-input v-model="form.user_name" placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="password_confirm">
					<el-input v-model="form.password_confirm" type="password" placeholder="请输入确认密码"></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="real_name">
					<el-input v-model="form.real_name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<div class="d-flex flex-column tips">
					<span>注意: 密码长度在9位以上，密码至少包含以下四种类别的字符中的任意三种</span>
					<span>（1）大写英文字母:A, B, C, … Z</span>
					<span>（2）小写英文字母:a, b, c, … z</span>
					<span>（3）阿拉伯数字 : 0, 1, 2, … 9</span>
					<span>（4）特殊字符如 : @, #, $, %, &, *等</span>
					<span>不允许有空格、中文，不能出现连续或重复数字，如12345、11111、abcd等</span>
				</div>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import {
		mapState
	} from 'vuex'
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				form: {
					user_name: '',
					password: '',
					password_confirm: '',
					real_name: '',
				},
				rules: {
					user_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'change'
					}],
				},
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user.user,
			}),
		},
		created() {
			this.getDetail()
		},
		methods: {
			getDetail() {
				this.form.user_name = this.user.user_name
				this.form.real_name = this.user.real_name
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/store.user/renew',
							},
							data: {
								user: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.$store.commit('logout')
								// 跳转后台首页
								this.$router.push({
									name: 'passport/login'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			},
		}
	}
</script>
<style scoped>
	
	.tips {
		padding: 10px 20px;
		border-radius: 10px;
		
		border: 1px solid #d6d6d6;
		color: #9c9c9c;
		background: #f9f9f9;
		
		font-weight: bold;
		margin-bottom: 20px;
		
		white-space: nowrap;
	}
</style>
